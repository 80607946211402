/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./tailwind.css";
@import "./assets//carmen-sans-webfont/style.css";
* {
    font-family: 'Source Sans Pro', sans-serif;
}

.vquip-font-heavy {
    font-family: 'Carmen Sans Heavy';
}

.vquip-font-medium {
    font-family: 'Carmen Sans Medium';
}

.vquip-progress-bar {
    height: .5rem;
}

.vquip-progress-bar-2 {
    height: 1.0rem;
}

.progress,
.progress-indeterminate,
:host(.ion-color) .progress,
 :host(.ion-color) .progress-indeterminate,
.progress,
.progress-indeterminate,
.indeterminate-bar-primary,
.indeterminate-bar-secondary,
.progress-buffer-bar {
    background: green!important;
}

ion-header {
    background-color: #fff;
}

ion-content,
ion-footer {
    --ion-background-color: #e2e8f0;
    background-color: #e2e8f0;
}

ion-content {
    padding: 1rem;
}

.min-h-half {
    //min-height: 50vh;
}

button[disabled] {
    background-color: #e2e8f0 !important;
    background: black;
    color: grey;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 700ms;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.invalid {
    border-color: red !important;
}

div.input-group .mat-error {
    position: absolute;
}

formly-field {
    margin-bottom: 1.1rem;
    display: block;
}

ion-modal.fullscreen {
    --width: 50%;
    --height: 50%;
    --border-radius: 0;
}

ion-modal.full {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

input:disabled,
select:disabled {
    background: #dddddd;
}