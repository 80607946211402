/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Carmen Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Regular'), url('Carmen Sans Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Thin'), url('Carmen Sans Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans UltraLight'), url('Carmen Sans UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans Light';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Light'), url('Carmen Sans Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Medium'), url('Carmen Sans Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans SemiBold'), url('Carmen Sans SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Bold'), url('Carmen Sans Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans ExtraBold'), url('Carmen Sans ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Carmen Sans Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Carmen Sans Heavy'), url('Carmen Sans Heavy.woff') format('woff');
    }